import Layout from '@/layout/TheLayout.vue'
import store from '@/store'
import { EXTRANET_GROUPS } from './utils'

export default [
  {
    path: '/',
    name: 'Reports',
    meta: {
      title: 'Reportes',
      showInSidebar: true,
      icon: 'mdi-library',
      excludeGroup: EXTRANET_GROUPS
    },
    component: Layout,
    children: [
      {
        path: '/reportes/anticipos',
        name: 'Advances Report',
        component: () => import('@/pages/Reports/Advances/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Anticipos',
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      // report disabled for usability review:
      // {
      //   path: '/reportes/control_asignacion_proveedores',
      //   name: 'Provider Assignment Control Report',
      //   component: () => import('@/pages/Reports/ProvidersAssignmentControlReport/index'),
      //   props: true,
      //   meta: {
      //     showInSidebar: true,
      //     title: 'Control de asignación a proveedores',
      //     ifCan: {
      //       view: 'Sinister/sinister'
      //     }
      //   }
      // },
      {
        path: '/corte_proveedores',
        name: 'Providers Cutoff',
        component: () => import('@/pages/Reports/ProvidersCutoff/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Corte de proveedores',
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/detalles_proveedor',
        name: 'Provider Details',
        component: () => import('@/pages/Reports/ProviderDetails/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Detalles de Proveedor',
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/reportes/datos_completos',
        name: 'Reporte de datos completos',
        component: () => import('@/pages/Reports/CompleteData/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Datos completos',
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/reportes/pagos_detenidos',
        name: 'Advances Stopped Report',
        component: () => import('@/pages/Reports/AdvancesStopped/index'),
        meta: {
          showInSidebar: true,
          title: 'Pagos detenidos',
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/reportes/facturados',
        name: 'Invoiced Report',
        component: () => import('@/pages/Reports/InvoicedReport/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Facturados',
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/reportes/pagados',
        name: 'Paid Report',
        component: () => import('@/pages/Reports/PaidReport/index'),
        props: true,
        meta: {
          showInSidebar: true,
          title: 'Pagados',
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/reportes/asignacion',
        name: 'AssignationReport',
        component: () => import('@/pages/Reports/AssignationReport'),
        meta: {
          title: 'Asignación',
          showInSidebar: true,
          ifCan: {
            'view_assignation_report': 'Sinister/sinister'
          }
        },
        props: (r) => ({
          hideBilling: !store.getters['account/userInGroup'](
            'CoDi',
            'GeOp',
            'Admin',
            'KAM',
            'ACo'
          )
        })
      },
      {
        path: '/reportes/clara',
        name: 'ClaraReport',
        component: () => import('@/pages/Reports/Clara'),
        meta: {
          title: 'Clara',
          showInSidebar: true,
          ifCan: {
            view: 'Quotation/quotationconceptclara'
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'macro',
    component: Layout,
    children: [
      {
        path: '/macro',
        name: 'Macro',
        component: () => import('@/pages/Macro'),
        meta: {
          title: 'Macro',
          showInSidebar: true,
          icon: 'mdi-view-column',
          excludeGroup: EXTRANET_GROUPS
        },
        props: (r) => ({
          codiMode:
            store.getters['account/userInGroup']('CoDi') ||
            store.getters['account/userInGroup']('Admin')
        })
      }
    ]
  },
  {
    path: '/',
    name: 'reportOne',
    component: Layout,
    children: [
      {
        path: '/reporte-1',
        name: 'Reporte #1',
        component: () => import('@/pages/Reports/ReportOne'),
        meta: {
          title: 'Reporte #1',
          showInSidebar: true,
          icon: 'mdi-numeric-1-box',
          ifInGroup: [
            'Admin',
            'GeOp',
            'KAM',
            'CoDi',
            'Supervisor',
            'ACo',
            'Finanzas'
          ],
          excludeGroup: EXTRANET_GROUPS
        },
        props: (r) => ({
          hideBilling: !store.getters['account/userInGroup'](
            'CoDi',
            'GeOp',
            'Admin',
            'KAM',
            'ACo',
            'Finanzas'
          )
        })
      }
    ]
  }
]
