/**
 * @vuepress
 * ---
 * title: sinisterTransformer
 * ---
 */

import Transformer from '../transformer'
import AffectedTransformer from './affected'
import advaceTransformer from '@/transformers/reports/Advance'

import moment from 'moment'
import 'moment/locale/es-mx'

const fetchDay = (value) => {
  return value ? moment(value).format('YYYY-MM-DD') : ''
}

function calculateMinorProcess(affecteds = []) {
  const [first] = affecteds.map(affected => {
    return {
      alternateProcess: affected.alternate_process,
      process: affected.process
    }
  })
    .sort(({ process: { number: numberA } }, { process: { number: numberB } }) =>
      numberA > numberB
    )
  return first
}

const userFullName = ({ treatment, name, first_name: firstName, last_name: lastName, mothers_last_name: secondLastName, username }) =>
  `${treatment || ''} ${firstName || name || username} ${lastName || ''} ${secondLastName || ''}`

/**
 * @class SinisterTransformer
 *
 * @author José Luis R. <jose.ruiz@jbge.com.mx>
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */
class SinisterTransformer extends Transformer {
  /**
   * Fetchs sinister list
   * @param sinisters The untransformed sinister list
   * @returns {Array} The transformed sinister list
   */
  static fetchCollection(sinisters) {
    return sinisters.map(sinister => {
      const affecteds = sinister.affecteds ? AffectedTransformer.fetchCollection(sinister.affecteds) : []
      const activeAffecteds = affecteds.filter(c => c.isActive)
      const minorProcess = activeAffecteds.length ? calculateMinorProcess(activeAffecteds) : ''

      const CustomerAdjuster = (sinister.customer_adjuster
        ? sinister.customer_adjuster.title + ' ' + sinister.customer_adjuster.name + ' ' + sinister.customer_adjuster.last_name + ' ' + sinister.customer_adjuster.mothers_last_name
        : null
      )
      return {
        affecteds: affecteds,
        'ContactAffected': affecteds,
        'jobTypeName': sinister.job_type.name,
        CostAnalyst: sinister.eco_user ? userFullName(sinister.eco_user) : '',
        'Customer': sinister.customer.name,
        'AffectedsLength': affecteds.filter(a => a.isActive).length,
        'SinisterId': sinister.id,
        'SinisterJobTypeName': sinister.job_type.name,
        'Policy': sinister.policy,
        'Inciso': sinister.inciso,
        'FolioE': sinister.folio_e,
        'InsuredName': sinister.insured_name,
        'Address': sinister.address,
        'OcurredDate': sinister.occurred_date,
        'AssignationDate': sinister.assignation_date,
        'AssignmentDate': sinister.assignment_date,
        'RepportChannel': (!!sinister.repport_channel && sinister.repport_channel.name) || null,
        'CustomerSupervisor': sinister.customer_supervisor.title + ' ' + sinister.customer_supervisor.name + ' ' + sinister.customer_supervisor.last_name + ' ' + sinister.customer_supervisor.mothers_last_name,
        CustomerAdjuster,
        'Zone': sinister.zone.name,
        'Supervisor': (!!sinister.supervisor && (sinister.supervisor.treatment + ' ' + sinister.supervisor.first_name + ' ' + sinister.supervisor.last_name + ' ' + sinister.supervisor.mothers_last_name)) || '',
        'NumSinister': sinister.sinister_number,
        'slug': sinister.slug,
        'jobType': sinister.job_type.slug,
        'reportChannel': (!!sinister.repport_channel && sinister.repport_channel.slug) || null,
        'ecoUser': !!sinister.eco_user && sinister.eco_user.username,
        'supervisor': (!!sinister.supervisor && sinister.supervisor.username) || '',
        'customerSupervisor': sinister.customer_supervisor.id,
        // 'customerAdjuster': sinister.customer_adjuster.id,
        'customer': sinister.customer,
        'zone': sinister.zone.slug,
        'zoneName': sinister.zone.name,
        sinisterNumber: sinister.sinister_number,
        'reportNumber': sinister.repport_number,
        'policy': sinister.policy,
        'inciso': sinister.inciso,
        'folio': sinister.folio,
        'insuredName': sinister.insured_name,
        'address': sinister.address,
        'origin': sinister.origin,
        'occurredDate': fetchDay(sinister.occurred_date),
        'repportComment': sinister.repport_channel_comment,
        'registerLocationLink': sinister.register_location_link,
        'AffectedsNumbers': affecteds.map((affected) => { return affected.process.number }), // Check this item
        minorProcess,
        'minorProcessAbbr': minorProcess.process ? minorProcess.process.abbreviation : '',
        'minorProcessName': minorProcess.process ? minorProcess.process.name : '',
        'minorProcessColor': minorProcess.process ? minorProcess.process.color : 'grey',
        'minorAltProcessAbbr': minorProcess.alternateProcess ? minorProcess.alternateProcess.abbreviation : '',
        'minorAltProcessName': minorProcess.alternateProcess ? minorProcess.alternateProcess.name : '',
        'minorAltProcessColor': minorProcess.alternateProcess ? minorProcess.alternateProcess.color : 'grey',
        'AffectedsAbbre': affecteds.map((affected) => { return affected.process.abbreviation }), // Check this item
        'auditor': sinister.auditor,
        'isActive': sinister.is_active
      }
    })
  }

  /**
   * Fetchs sinister detail
   * @param sinister The untransformed sinister
   * @returns {Object} The transformed sinister
   */
  static fetch(sinister) {
    const hasPermission = sinister.has_permission

    if (!hasPermission) {
      return { hasPermission }
    }

    const ACo = sinister.eco_user
    const Supervisor = sinister.supervisor
    const SupervisorDev = sinister.supervisor_dev
    const customerSuper = sinister.customer_supervisor
    const customerAdj = sinister.customer_adjuster
    const Auditor = sinister.auditor
    const customer = sinister.customer
    const advances = sinister.advances ? advaceTransformer.fetchCollection(sinister.advances) : []
    const affecteds = sinister.affecteds ? AffectedTransformer
      .fetchCollection(sinister.affecteds)
      .filter(a => a.isActive)
      .sort((a, b) => b.id - a.id) : []

    return {
      hasPermission,
      affecteds,
      advances,
      addDate: sinister.add_date,
      'ContactAffected': affecteds,
      'jobType': sinister.job_type.slug,
      'jobTypeName': sinister.job_type.name,
      'reportChannel': sinister.repport_channel && sinister.repport_channel.slug,
      'reportChannelName': sinister.repport_channel && sinister.repport_channel.name,
      'ACoUser': ACo && ACo.username,
      ACoUserFullname: ACo && userFullName(ACo),
      'supervisor': Supervisor && Supervisor.username,
      supervisorFullname: Supervisor && userFullName(Supervisor),
      'supervisorDevFullname': SupervisorDev && userFullName(SupervisorDev),
      'customer': customer,
      'customerCodename': customer.codename,
      'customerName': customer.name,
      'customerSupervisor': customerSuper && customerSuper.id,
      customerSupervisorName: customerSuper ? userFullName(customerSuper) : null,
      'customerAdjuster': customerAdj && customerAdj.id,
      customerAdjusterName: customerAdj ? userFullName(customerAdj) : null,
      customerAdjusterPhone: customerAdj ? customerAdj.cellphone || customerAdj.phone : null,
      'zone': sinister.zone.slug,
      'zoneName': sinister.zone.name,
      'sinisterNumber': sinister.sinister_number,
      'reportNumber': sinister.repport_number,
      'policy': sinister.policy,
      'inciso': sinister.inciso,
      'folio': sinister.folio,
      'folioE': sinister.folio_e,
      'insuredName': sinister.insured_name,
      'address': sinister.address,
      'occurredDate': sinister.occurred_date,
      'assignmentDate': sinister.assignment_date,
      'reportComment': sinister.repport_channel_comment,
      'registerLocationLink': sinister.register_location_link,
      'auditor': Auditor ? Auditor.codename : null,
      'auditorName': Auditor ? Auditor.name : null,
      'isActive': sinister.is_active,
      'slug': sinister.slug,
      'id': sinister.id,
      'partNumber': sinister.part_number,
      'ECOUserFullname': sinister.eco_user ? userFullName(sinister.eco_user) : ''
    }
  }

  static send(sinister) {
    return {
      'job_type': sinister.jobType,
      'repport_channel': (!!sinister.reportChannel && sinister.reportChannel) || null,
      'eco_user': (!!sinister.ACoUser && sinister.ACoUser) || null,
      'supervisor': (!!sinister.supervisor && sinister.supervisor) || null,
      'customer': sinister.customer,
      'customer_supervisor': sinister.customerSupervisor,
      'customer_adjuster': sinister.customerAdjuster,
      'zone': sinister.zone,
      'sinister_number': sinister.sinisterNumber,
      'repport_number': sinister.reportNumber,
      'policy': sinister.policy,
      'inciso': sinister.inciso,
      'folio_e': sinister.folioE || null,
      'origin': sinister.origin || null,
      'insured_name': sinister.insuredName,
      'address': sinister.address,
      'occurred_date': sinister.occurredDate,
      'assignment_date': moment(sinister.assignmentDate).utc(),
      'repport_channel_comment': sinister.reportComment,
      'register_location_link': sinister.registerLocationLink,
      'auditor': sinister.auditor || null,
      'part_number': sinister.partNumber
    }
  }
}
export default SinisterTransformer
