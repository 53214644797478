/**
 * @vuepress
 * ---
 * title: complaintTransformer
 */

import Transformer from '@/transformers/transformer'
import moment from 'moment'
import 'moment/locale/es-mx'

/**
 * @class invoiceTransformer
 * Invoice transformer for casualties
 * @author Jorge Nunez L.
 */

const complementTransformer = ({ uuid, folio, xml, pdf, amount, date, add_date: addDate }) => {
  return {
    uuid,
    folio,
    xml,
    pdf,
    amount,
    date: date ? moment(date).format('DD/MM/YYYY') : null,
    addDate: addDate ? moment(addDate).format('DD/MM/YYYY') : null
  }
}
class invoiceTransformer extends Transformer {
  static fetch({ folio, pdf, xml, serie, uuid, date, add_date: addDate, total, subtotal,
    complement_invoice: complementInvoice
  }) {
    return {
      folio,
      pdf,
      xml,
      serie,
      total,
      subtotal,
      complementInvoice: complementInvoice.length ? complementInvoice.map((c) => complementTransformer(c)) : [],
      uuid,
      date: moment(date).format('DD/MM/YYYY'),
      addDate: moment(addDate).format('DD/MM/YYYY')
    }
  }
}
export default invoiceTransformer
