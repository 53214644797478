import Transformer from '@/transformers/transformer'

class AVIGroupTransformer extends Transformer {
  static fetch({ grp_path: path, grp_name: name, documents }) {
    return {
      name, path,
      documents: documents.map(({ doc_name: name, ...data }) => ({ name, ...data }))
    }
  }
}

class AVISessionTransformer extends Transformer {
  static fetch({ widget, user, groups }) {
    return {
      user,
      widget: {
        color: widget.agt_color,
        imagePath: widget.agt_image_path,
        message: widget.agt_message,
        name: widget.agt_name
      },
      groups: AVIGroupTransformer.fetchCollection(groups)
    }
  }
}

export default AVISessionTransformer
